import React from "react"
// import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import { Layout } from "../components/index"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { LowerHeader, LowerHeaderBg } from "../components/LowerPages/index"
import "../styles/LowerPage.scss";
import * as styles from "../styles/Success.module.scss"

const Success = () => {
  const breakpoints = useBreakpoint();

  const title1 = [
    { "id": 0, "text": "c" },
    { "id": 1, "text": "o" },
    { "id": 2, "text": "n" },
    { "id": 3, "text": "t" },
    { "id": 4, "text": "a" },
    { "id": 5, "text": "c" },
    { "id": 6, "text": "t" },
  ];
  return (
    <Layout className={`contact`} pageName={`contact`} title={`お問い合わせ完了`}>
      <Seo
        title={`お問い合わせ完了`}
      />
      <div className={`LowerHeadImg`}>
        {
          breakpoints.sm ?
            <StaticImage
              layout="fullWidth"
              src="../images/contact/contact_header-sp.png"
              alt="contact_header"
            />
            :
            <StaticImage
              layout="fullWidth"
              src="../images/contact/contact_header.png"
              alt="contact_header"
            />

        }
      </div>
      <div className={`LowerWrapper`}>
        <LowerHeader data={title1} title={`お問い合わせ`}>
          <LowerHeaderBg />
        </LowerHeader>
        <div className={`LowerContent`}>
          <div className={`RadiusCircle`} ></div>
          <div className={`LowerContent__inner`}>
            <div className={`LowerLead`}>
              <p>
                弊社へのお問い合わせは下記のメールフォームをご利用ください。<br />
                お客様からいただいた個人情報は、慎重に取り扱い、<br />
                お客様の同意なしに第三者に提供または開示をすることはありません。<br />
                個人情報保護方針については<a href="/privacy/">こちら</a>をご覧ください。
              </p>
            </div>
            <div className={styles.Success__result}>送信が完了いたしました。</div>
            <div className={`${styles.Success__icon}`}>
              {
                breakpoints.sm ?
                  <StaticImage
                    layout="fullWidth"
                    src="../images/contact/success-sp.png"
                    alt="success"
                    placeholder="none"
                  />
                  :
                  <StaticImage
                    layout="fullWidth"
                    src="../images/contact/success.png"
                    alt="success"
                    placeholder="none"
                  />

              }
            </div>
            <div className={styles.Success__endmsg}>
              <p>
                この度は、お問い合わせフォームよりご連絡をいただき、ありがとうございます。<br />
                ご記入の内容でお問い合わせを受け付けました。<br />
                内容を確認し、折り返し担当の者よりご連絡いたします。<br />
                今後とも、深川養鶏農業協同組合をよろしくお願いいたします。
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Success